import React from "react"
import Layout from '../layouts/layout'
import { graphql } from 'gatsby'
import Helmet from "react-helmet"

import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

import Style from './post.module.scss'

export const query = graphql`
    query ($slug: String, $language: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}, language: {eq: $language}}) {
            frontmatter {
                title
                date(formatString: "LL", locale: $language)
                fulldate: date
                next
                previous
                description
                language
                slug
            }
            html
            timeToRead
        },
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
              node {
                ns
                data
                language
              }
            }
        }
    }
    `

const Post = (props) => {
    const {t} = useTranslation();

    return (
        <>
            <Layout>
                <Helmet>
                    <title>{props.data.markdownRemark.frontmatter.title}</title>
                    <meta name="description" content={props.data.markdownRemark.frontmatter.description} />
                    <meta property="og:site_name" content="timotheebinet.com"/>
                    <meta property="og:type" content="article" />
                    <meta property="og:locale" content={props.data.markdownRemark.frontmatter.language === 'fr' ? 'fr_FR' : 'en_US'}/>
                    <meta property="og:url" content={'https://timotheebinet.com/' + props.data.markdownRemark.frontmatter.language + '/blog/' + props.data.markdownRemark.frontmatter.slug}/>
                    <meta property="og:title" content={props.data.markdownRemark.frontmatter.title} />
                    <meta property="og:description" content={props.data.markdownRemark.frontmatter.description} />
                    <meta property="article:published_time" content={props.data.markdownRemark.frontmatter.fulldate}/>
                    <meta property="article:modified_time" content={props.data.markdownRemark.frontmatter.fulldate}/>
                    <meta property="twitter:title" content={props.data.markdownRemark.frontmatter.title} />
                    <meta property="twitter:description" content={props.data.markdownRemark.frontmatter.description} />
                </Helmet>
                <Link className={Style.arrow} to="/blog">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={Style.arrow_svg} d="M26.241 25L39.5304 12.4481C40.9077 11.0708 41.6666 9.23975 41.6666 7.2917C41.6667 3.27148 38.3952 0 34.375 0C32.4341 0 30.6071 0.753809 29.2318 2.12402L10.4695 19.8436C9.45431 20.8577 8.76661 22.1334 8.4798 23.5362C8.38214 24.0225 8.33331 24.5148 8.33331 25.0001C8.33331 25.4854 8.38214 25.9776 8.4798 26.469C8.76671 27.8678 9.45431 29.1424 10.4899 30.1759L29.2195 47.8639C30.597 49.2411 32.428 50 34.375 50C38.3952 50 41.6667 46.7285 41.6667 42.7083C41.6667 40.7593 40.9078 38.9282 39.5102 37.5315L26.241 25Z" />
                    </svg>
                </Link>
                
                <article className={Style.wrapper + " postTemplate"}>
                    <h1 className={Style.title_h1} >{props.data.markdownRemark.frontmatter.title}</h1>
                    <div className={Style.data}>
                        <time dateTime={props.data.markdownRemark.frontmatter.fulldate} className={Style.time} >{t("post_time")} {props.data.markdownRemark.frontmatter.date}</time>
                        <p className={Style.read} >{props.data.markdownRemark.timeToRead}min {t("post_read")}</p>
                    </div>
                    <div className={Style.inner} dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
                    <div className={Style.links}>
                        { props.data.markdownRemark.frontmatter.previous && (
                            <Link className={Style.left + ' ' + Style.link} to={`/blog/`+ props.data.markdownRemark.frontmatter.previous}>
                                {t("post_previous")}
                            </Link>
                            )
                        }
                        { props.data.markdownRemark.frontmatter.next && (
                            <Link className={Style.right + ' ' + Style.link} to={`/blog/`+ props.data.markdownRemark.frontmatter.next}>
                                {t("post_next")}
                            </Link>
                            )
                        }
                    </div>
                </article>
            </Layout>
        </>
    )
}

export default Post
